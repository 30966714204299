import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      marginBottom: "4rem",
      marginTop: "2rem"
    },
  })
);
