import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  root: {
    "& .MuiPaper-root": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "50px 20px",

      "& h3": {
        marginBottom: "20px",
      },
    },
  },
});
