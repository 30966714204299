import { IconButton } from "@material-ui/core";
import { FeedbackOutlined, HelpOutlineOutlined, HomeOutlined } from "@material-ui/icons";
import * as ROUTES from "../../routes/routes";
import { analytics } from "../../config/firebase";
import { useStyles } from "../useStyles";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

const BottomNav = () => {
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();

  const [mode, setMode] = useState("public");

  const routeTo = (route: string) => {
    history.push(route);
    window.scroll(0, 0);
  };

  const openNewTab = (url: string) => {
    window.open(url, "_blank");
  };

  useEffect(() => {
    if (location.pathname === ROUTES.PROFILE) {
      setMode("profile");
    } else if (location.pathname === ROUTES.GEN_ART) {
      setMode("create");
    } else if (location.pathname.startsWith("/artwork")) {
      setMode("artwork");
    } else if (location.pathname.startsWith("/mint")) {
      setMode("mint");
    } else if (location.pathname.startsWith("/collector")) {
      setMode("collector");
    } else {
      setMode("public");
    }
  }, [location]);

  return (
    <div className={classes.bottombar}>
      <IconButton
        className={mode === "public" ? classes.activeIcon : ""}
        onClick={() => {
          analytics.logEvent("Click explore bottom nav");
          routeTo(ROUTES.PUBLIC_FEED);
        }}
      >
        <HomeOutlined />
        Explore
      </IconButton>
      <IconButton onClick={() => openNewTab("https://www.artwork.rocks/welcome-to-women-artwork-rocks/")}>
        <HelpOutlineOutlined />
        Info
      </IconButton>
      <IconButton
        onClick={() => openNewTab("http://www.artwork.rocks/contact-us/")}
      >
        <FeedbackOutlined />
        Feedback
      </IconButton>
    </div>
  );
};

export default BottomNav;
