import { useMediaQuery } from "@material-ui/core";
import { useStyles } from "./useStyles";

interface IAnimalFeatures {
  borderStyle?: boolean;
  img: string | null;
  uid: string | null;
  svg: string | null;
  url: string | null;
}

export const BaseInsect = (props: IAnimalFeatures) => {
  const classes = useStyles();
  const widthRsp = useMediaQuery("(min-width:500px)");

  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          position: "absolute",
          borderRadius: "20px 20px 0 0 !important",
        }}
      >
        <div
          className={
            widthRsp
              ? !props.borderStyle
                ? classes.fixed
                : classes.fixedWithBorder
              : !props.borderStyle
              ? classes.fixedRsp
              : classes.fixedRspWithBorder
          }
        >
          {props.svg ? (
            <div dangerouslySetInnerHTML={{ __html: props.svg }}></div>
          ) : props.img ? (
            <img
              loading="lazy"
              style={{ width: "inherit" }}
              src={"data:image/png;base64," + props.img}
              alt=""
            />
          ) : props.url ? (
            <img
              style={{ width: "inherit" }}
              src={"https://" + props.url}
              alt=""
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};
