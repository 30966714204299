import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Popover,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { DeleteOutline, ShoppingCartOutlined } from "@material-ui/icons";
import React, { memo, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useLocation } from "react-router-dom";
import {
  EmailIcon,
  EmailShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

import { useStyles } from "./useStyles";
import { BaseInsect } from "../../BaseInsect/BaseInsect";
import upvote from "../../../../assets/images/upvote.svg";
import colorUpvote from "../../../../assets/images/color_upvote.svg";
import share from "../../../../assets/images/share.svg";
import { auth, db, analytics } from "../../../../config/firebase";
import { apiPostRequest } from "../../../../utils/ApiRequest";
import { isMobile } from "react-device-detect";

const upvoteImage = apiPostRequest("art/upvoteImage");
const unvoteImage = apiPostRequest("art/unvoteImage");

const DeleteDialog = ({ open, handleClose, handleDelete }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle id="alert-dialog-title">
        Do you want to delete this butterfly?
      </DialogTitle>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => handleDelete()} color="primary" autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const MobileMetaDialog = ({
  open,
  handleClose,
  handleContinue,
  handleDeeplink,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <DialogTitle id="alert-dialog-title">
        Are you inside metamask mobile?
      </DialogTitle>
      <DialogActions style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>
        <Button onClick={() => handleContinue()} color="primary" variant="contained">
          Yes
        </Button>
        <Button onClick={() => handleDeeplink()} color="secondary" variant="contained">
          Open Metamask
        </Button>
      </DialogActions>
    </Dialog>
  );
};

/****
 * @description: Base displayed Card housing art
 * @author: Nabil Alamin (@arndom)
 * ***/

declare let window: any;

const Card = ({
  data,
  img,
  uid,
  url,
  svg,
  name,
  creator,
  creatorUid,
  profile,
  created,
  upCount,
  downCount,
}) => {
  const classes = useStyles();
  const widthRsp1 = useMediaQuery("(min-width:500px)");
  const [user, setUser] = useState<any>();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [shareAnchor, setShareAnchor] = useState<HTMLButtonElement | null>(
    null
  );
  const location = useLocation();
  const [mintMode, setMintMode] = useState(false);
  const [voteImage, setVoteImage] = useState(upvote);

  const handleClose = () => setAnchorEl(null);

  const handleShare = (event: React.MouseEvent<HTMLButtonElement>) => {
    analytics.logEvent("Click card Share");
    setShareAnchor(event.currentTarget);
  };

  const handleShareClose = () => setShareAnchor(null);

  const unvoteImageHandler = async (imageUid) => {
    let userToken = await auth.currentUser!.getIdToken(true);
    await unvoteImage({ userToken, imageUid });
  };

  const upvoteImageHandler = async (imageUid) => {
    analytics.logEvent("click card Upvote");
    let userToken = await auth.currentUser!.getIdToken(true);
    if (upCount && upCount.includes(user.uid)) {
      await unvoteImageHandler(imageUid);
      setVoteImage(upvote)
    } else {
      await upvoteImage({ userToken, imageUid });
      setVoteImage(colorUpvote);
    }
  };

  //upvote icon handler
  useEffect(() => {
    if (user !== undefined && upCount && upCount.includes(user.uid)) setVoteImage(colorUpvote);
  }, [upCount, user]);

  const open = Boolean(anchorEl);
  const openShare = Boolean(shareAnchor);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    auth.onAuthStateChanged((userAuth) => {
      if (userAuth) setUser(userAuth);
    });
    // eslint-disable-next-line
  }, []);

  const deleteSaved = (data) => {
    auth.onAuthStateChanged((userAuth) => {
      if (userAuth) {
        const dbRef = db.collection("savedImages");

        dbRef
          .doc(data.uid)
          .delete()
          .then(() => {
            toast.success("Liberated 🦋");
          })
          .catch((e) => {
            console.log(e.message);
          });
      }
    });
  };

  useEffect(() => {
    if (location.pathname.startsWith("/mint")) {
      setMintMode(true);
    }
  }, [location]);

  const shareLink = `${window.location.origin}/artwork/${uid}`;
  const artworkLink = `/artwork/${uid}`;
  const collectorLink = `/collector/${creatorUid}`;
  const currentOrigin = window.location.origin;

  //mobile dialog functions/definitions
  const [openMobileMeta, setOpenMobileMeta] = useState(false);
  const handleMobileMetaOpen = () => setOpenMobileMeta(true);
  const handleMobileMetaClose = () => setOpenMobileMeta(false);
  const handleDeeplink = () =>
    window.open(
      `https://metamask.app.link/dapp/${currentOrigin}/mint/${uid}`,
      "_self"
    );
  const handleLink = () => window.open(`/mint/${uid}`, "_self");

  const handleBuy = () => {
    analytics.logEvent("Click card Buy");
    if(isMobile) return handleMobileMetaOpen();
    handleLink();
  };

  //delete dialog functions/definitions
  const [openDelete, setOpenDelete] = useState(false);
  const handleDeleteOpen = () => setOpenDelete(true);
  const handleDeleteClose = () => setOpenDelete(false);
  const handleDelete = () => deleteSaved(data);

  return (
    <div data-aos="fade-down" data-aos-once="true">
      <div className={classes.mapHolder}>
        
        {/*****************************
         *      Top Bar
         * ***************************/}
        {!mintMode && (
          <div className={classes.artProfile}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Link to={collectorLink}>
                <IconButton
                  onClick={(e) => {
                    analytics.logEvent("Click card Profile");
                  }}
                >
                  <img
                    src={profile === "" ? "https://picsum.photos/36" : profile}
                    alt="profile"
                    style={{ borderRadius: "100%", width: "36px" }}
                  />
                </IconButton>
              </Link>
              <div>
                <Link to={collectorLink} style={{ color: "black" }}>
                  <p style={{ fontSize: "16px" }}>
                    {creator === "" ? "Human" : creator}
                  </p>
                </Link>
                <Link to={artworkLink}>
                  <p
                    style={{
                      fontSize: "12px",
                      color: "rgba(135, 115, 161, 1)",
                    }}
                  >
                    {new Date(created.seconds * 1000).toDateString()}
                  </p>
                </Link>
              </div>
            </div>

            {/*****************************
             *      PopOvers & Modals
             * ***************************/}
            <>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <Typography className={classes.typography}>
                  <p>Saved</p>
                  <p>Minting</p>
                  <p>Minted</p>
                </Typography>
              </Popover>

              <Popover
                id={id}
                open={openShare}
                anchorEl={shareAnchor}
                onClose={handleShareClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <div className={classes.share}>
                  <EmailShareButton
                    url={shareLink}
                    className={classes.sharedAppStyle}
                  >
                    <EmailIcon size={32} round />
                  </EmailShareButton>
                  <TelegramShareButton
                    url={shareLink}
                    className={classes.sharedAppStyle}
                  >
                    <TelegramIcon size={32} round />
                  </TelegramShareButton>
                  <TwitterShareButton
                    url={shareLink}
                    className={classes.sharedAppStyle}
                  >
                    <TwitterIcon size={32} round />
                  </TwitterShareButton>
                  <WhatsappShareButton
                    url={shareLink}
                    className={classes.sharedAppStyle}
                  >
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>
                </div>
              </Popover>

              <DeleteDialog
                open={openDelete}
                handleClose={handleDeleteClose}
                handleDelete={handleDelete}
              />

              <MobileMetaDialog
                open={openMobileMeta}
                handleClose={handleMobileMetaClose}
                handleContinue={handleLink}
                handleDeeplink={handleDeeplink}
              />
            </>

            {/*****************************
             *      Buy Button
             * ***************************/}
            {user?.uid === creatorUid && (
              <Button
                className={classes.shoppingCart}
                aria-describedby={id}
                onClick={handleBuy}
              >
                <ShoppingCartOutlined />
                Buy
              </Button>
            )}
          </div>
        )}

        {/*****************************
         *      Main Display
         * ***************************/}
        <div className={widthRsp1 ? classes.artHolder : classes.artHolderRsp1}>
          <Link to={artworkLink}>
            <BaseInsect
              key={2}
              svg={svg}
              borderStyle={true}
              img={img}
              uid={uid}
              url={url}
            />
          </Link>
        </div>

        {/*****************************
         *      Name Title Bar
         * ***************************/}
        <div className={classes.nameTitle}>
          <Link to={artworkLink}>
            <p className={classes.butterflyName}>{name + "🦋"}</p>
          </Link>
        </div>

        {/*****************************
         *      Bottom Bar
         * ***************************/}
        {!mintMode && (
          <div className={classes.artButtons}>
            <IconButton onClick={handleShare}>
              <img src={share} alt="share" />
            </IconButton>

            <IconButton onClick={() => upvoteImageHandler(uid)}>
              <p className={classes.counter}>
                {upCount.length === 0 ? "" : upCount.length}
              </p>
              <img src={voteImage} alt="upvote" />
            </IconButton>

            {user?.uid === creatorUid && (
              <IconButton onClick={handleDeleteOpen}>
                <DeleteOutline className={classes.deleteOutline} />
              </IconButton>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(Card);
