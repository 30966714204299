import React, { useEffect, useState } from "react";
// import { useMediaQuery } from "@material-ui/core";
import { useParams } from "react-router-dom";

import { auth } from "../../config/firebase";
import { withLayout } from "../../Layout/Layout";
import DisplayCard from "../../components/layout/cardLayout/DisplayCard/DisplayCard";
import { LoadingOverlay } from "../../components/overlays/LoadingOverlay/LoadingOverlay";
// import { useStyles } from "./useStyles";
// import NextButton from "../../components/FeedButtons/NextButton/NextButton";
// import PrevButton from "../../components/FeedButtons/PrevButton/PrevButton";
// import { scrollToTop } from "../../utils/common";
import { feedVariables } from "../../utils/feedVariables";
/***
 * @description: user public collection
 * @author: Tailor VJ (@tailorvj)
 * ***/

type UserPublicParams = {
  userid: string;
};

const PublicUserProfile = () => {
  const [data, setData] = useState<any>([]);
  const [loadOverlay, setLoadOverlay] = useState(false);

  const { userid } = useParams<UserPublicParams>();

  const collectionRef = feedVariables.feedCollection.where(
    "creatorUid",
    "==",
    userid
  );
  const itemsPerPage = feedVariables.itemsPerPage;
  const orderField = feedVariables.orderField;

  const lengthOffset = itemsPerPage - 1;
  const [lastItemIndex, setlastItemIndex] = useState(lengthOffset);
  const [nextExists, setNextExists] = useState(false);

  const fetchData = (query: any) => {
    auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        setLoadOverlay(true);
        query.onSnapshot((snapshot) => {
          const _data = snapshot.docs.map((doc) => doc.data());
          const reData = data.concat(_data);

          console.log(reData.length);
          reData.length % 12 === 0 ? setNextExists(true) : setNextExists(false);

          setData(reData);
          setlastItemIndex(reData.length - 1);
          setLoadOverlay(false);
        });
      }
    });
  };

  const nextPage = (last) => {
    const query = collectionRef
      .orderBy(orderField, "desc")
      .startAfter(last[orderField])
      .limit(itemsPerPage);
    fetchData(query);
  };

  useEffect(() => {
    setLoadOverlay(true);
    const query = collectionRef.orderBy(orderField, "desc").limit(itemsPerPage);
    fetchData(query);
    //eslint-disable-next-line
  }, []);

  const handleNext = () =>
    nextExists && data[lastItemIndex] && nextPage(data[lastItemIndex]);

  // useHorizontalScroll(handleNext);

  useEffect(() => {
    const hScroll = (e) => {
      const element = document.getElementById("feed");
      if (feedVariables.isBottom(element)) handleNext();
    };

    document.addEventListener("scroll", hScroll);

    return () => {
      document.removeEventListener("scroll", hScroll);
    };
  });

  return (
    <div>
      <DisplayCard
        data={data}
        responsiveClass="containerRspFeed"
        id="feed"
      />

      <LoadingOverlay type={true} open={loadOverlay} transaction={false} />
    </div>
  );
};

export default withLayout(PublicUserProfile);
