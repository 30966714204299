import { useHistory } from "react-router-dom";
import { useStyles } from "../useStyles";
import * as ROUTES from "../../routes/routes";
import logo from "../../assets/svgs/Navbar/WIBT-logo.svg";
import { DesktopProfile } from "../DesktopProfile/DesktopProfile";
import { DesktopFAB } from "../DesktopFAB/DesktopFAB";
import { routeTo } from "../common";


interface INav{
    title: string
}

export const DesktopNav = ({title}:INav) => {
    const classes = useStyles();
    const history = useHistory();
    
    return (
      <>
        <div
          onClick={() => routeTo(ROUTES.PUBLIC_FEED, history)}
          className={classes.logo}
        >
          <img
            alt="logo"
            src={logo}
            className={classes.logoImg}
          />
        </div>
  
        <>
          <p className={classes.nav}>
              {title}
          </p>
  
          <div className={classes.profile}>
            <DesktopProfile />
            <DesktopFAB />
          </div>
        </>      
      </>
    );
  };