import { Badge, withStyles } from "@material-ui/core";

export const StyledBadge = withStyles((theme) => ({
  badge: {
    right: "-36px",
    top: "-12px",
    borderRadius: "100%",
    padding: "12px 5px",
    color: "#2B2C7C",
    backgroundColor: "#65E5E5",
    fontSize: "12px",
    fontWeight: 500,
    fontFamily: "Poppins",
  },
}))(Badge);


export const routeTo = (route: string, history: any) => {
  history.push(route);
  window.scroll(0, 0);
};