import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  page: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    background: "#EBFFFF",
    color: "#2B2C7C",
    padding: "29px 35px",

    "@media (max-width: 767px)": {
      padding: "18px 21px",
      height: "var(--app-height)",
    },
  },

  content: {
    marginLeft: "5%",
    marginTop: "6%",
    zIndex: 100,

    "@media (max-width: 767px)": {
      marginLeft: "0",
      marginTop: "25%",
    },

    "@media (max-width: 500px)": {
      marginLeft: "0",
      marginTop: "50%",
    },
  },

  rainbow: {
    position: "absolute",
    top: 0,
    right: 0,
    width: "50%",
    height: "100%",
    overflow: "hidden",

    "& img": {
      height: "100%",
    },

    "@media (max-width: 767px)": {
      display: "none",
    },
  },

  rainbowMobile1: {
    "@media (min-width: 767px)": {
      display: "none",
    },

    position: "absolute",
    top: 0,
    right: 0,
    // width:"50%",
    height: "100%",
    overflow: "hidden",
  },

  rainbowMobile2: {
    "@media (min-width: 767px)": {
      display: "none",
    },

    position: "absolute",
    bottom: 0,
    left: 0,
    "& img": {
      height: "100%",
      width: "100%",
    },
  },

  warLogo: {
    display: "flex",
    alignItems: "center",
    marginBottom: "24px",

    "@media (max-width: 767px)": {
      marginBottom: "16px",
    },

    "& img": {
      marginRight: "12px",

      "@media (max-width: 767px)": {
        width: "20px",
        height: "18px",
      },
    },

    "& p": {
      fontFamily: "Poppins",
      fontSize: "24px",
      fontWeight: 400,

      "@media (max-width: 767px)": {
        fontSize: "16px",
      },
    },
  },

  underTag: {
    fontFamily: "IBM Plex Sans",
    fontSize: "18px",
    fontWeight: 400,

    "@media (max-width: 767px)": {
      fontSize: "16px",
    },
  },

  tagLine: {
    margin: "24px 0",

    "@media (max-width: 767px)": {
      margin: "16px 0",
    },

    "& p": {
      fontFamily: "IBM Plex Sans Hebrew",
      fontSize: "40px",
      fontWeight: 700,

      "@media (max-width: 767px)": {
        fontSize: "24px",
      },
    },
  },

  googleSign: {
    display: "flex",
    alignItems: "center",
    padding: "19px 41.5px",
    border: "1px solid #F3F3F3",
    background: "#FCFCFC",
    width: "fit-content",
    boxShadow: "0px 0px 20px 0px #8773A166",
    borderRadius: "8px",
    margin: "24px 0",
    cursor: "pointer",

    "@media (max-width: 767px)": {
      margin: "16px 0",
    },

    "& img": {
      marginRight: "10px",
    },

    "& p": {
      fontFamily: "Rubik",
      fontSize: "16px",
      fontWeight: "400",
    },

    "&:hover": {
      boxShadow: "0px 0px 40px 10px #8773A166",
    },
  },

  policyHolder: {
    display: "flex",
    alignItems: "center",

    "& .MuiFormControlLabel-root": {
      marginRight: "0px",
    },
  },

  policy: {
    "& .MuiIconButton-label > input": {
      width: "33px",
      height: "33px",
    },

    "& .MuiIconButton-label > img": {
      width: "15px",
      height: "15px",
    },
  },

  policyText: {
    fontFamily: "IBM Plex Sans",
    fontSize: "12px",
    fontWeight: 400,
    cursor: "pointer",
  },
});