import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  page: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 15rem",
    background: "white",
    overflow: "hidden",
    // paddingTop: "4%",
    paddingTop: "95px",
    paddingBottom: "calc(100vh - 5%)",
    // height:"calc(100vh - 80px)"
  },

  pageRsp: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: "white",
    overflow: "hidden",
    paddingTop: "35%",
    paddingBottom: "calc(100vh - 50%)",
    // height: "calc(var(--app-height) - 80px)"
  },

  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "400px",
    width: "400px",
    boxShadow: "0px 0px 1px 10px rgb(255 255 255 / 15%)",
    background: "white",
    borderRadius: "5px",
  },

  containerRsp1: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "300px",
    width: "300px",
    boxShadow: "0px 0px 1px 10px rgb(255 255 255 / 15%)",
    background: "white",

    borderRadius: "5px",
  },

  loaderContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "400px",
    width: "400px",
    boxShadow: "0px 0px 10px 0px rgb(0 0 0 / 100%)",
    background: "white",
    borderRadius: "5px",
  },

  loaderContainerRsp1: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "300px",
    width: "300px",
    boxShadow: "0px 0px 10px 0px rgb(0 0 0 / 100%)",
    background: "white",
    borderRadius: "5px",
  },

  artHolder: {
    height: "400px",
    width: "400px",
  },

  artHolderRsp1: {
    height: "300px",
    width: "300px",
  },

  buttonHolder: {
    display: "flex",
    alignItems: "center",
    marginTop: "20px",
  },

  buttonHolderRsp: {
    display: "flex",
    alignItems: "center",
    marginTop: "20px",
  },

  buttonFav: {
    background: "rgba(36, 198, 149, 1)",
    color: "white",
    textTransform: "none",
    display: "flex",
    alignItems: "center",
    width: "4.5rem",
    height: "4.5rem",
    borderRadius: "100%",

    "& .MuiButton-label": {
      display: "flex",
      flexDirection: "column",

      "& p": {
        fontSize: ".7rem",
        marginTop: "-.2rem",
        color: "white",
      },
    },

    "&.MuiButton-root:hover": {
      background: "rgba(36, 198, 149, 1)",
      color: "white",

      "& p": {
        color: "white",
      },
    },

    "& img": {
      filter: "brightness(0) invert(1)",
      paddingBottom: "5px",
    },

    marginLeft: "1.5rem",
  },

  buttonNext: {
    background: "rgba(211, 84, 95, 1)",
    color: "white",
    textTransform: "none",
    display: "flex",
    alignItems: "center",
    width: "4.5rem",
    height: "4.5rem",
    flexDirection: "column",
    borderRadius: "100%",

    "& .MuiButton-label": {
      display: "flex",
      flexDirection: "column",

      "& p": {
        fontSize: ".7rem",
        marginTop: "-.2rem",
        color: "white",
      },
    },

    "&.MuiButton-root:hover": {
      color: "white",
      background: "rgba(211, 84, 95, 1)",

      "& p": {
        color: "white",
      },
    },

    "& img": {
      filter: "brightness(0) invert(1)",
      paddingBottom: "5px",
    },
  },

  generation: {
    "&.MuiIconButton-root": {
      width: "48px",
      height: "48px",
    },
  },

  selectedGeneration: {
    border: "1px solid hsla(108, 62%, 55%, .8)",
    textTransform: "none",
    color: "white",
    display: "flex",
    alignItems: "center",
    background: "white",

    "&.MuiIconButton-root": {
      width: "48px",
      height: "48px",
    },

    "&.MuiIconButton-root:hover": {
      background: "white",
    },
  },

  toastContent: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    width: "fit-content",

    "& img": {
      marginRight: "10px",
    },
  },

  successToast: {
    fontSize: "14px",
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
    fontFamily: "Poppins",
    background: "#247715",
    color: "white",
    marginTop: "80px",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.12)",
  },

  regularToast:{
    fontSize: "14px",
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
    fontFamily: "Poppins",
    marginTop: "80px",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.12)",
  },

  helper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    marginTop: "20px",

    "& p": {
      fontFamily: "Poppins",
      fontWeight: 500,
      fontSize: "14px",
      color: "#8773A1",
    },
  },
});
