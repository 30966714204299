import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  fixed: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "400px",
    width: "400px",

    "& svg": {
      height: "400px",
      width: "400px",
    },
  },

  fixedWithBorder: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "400px",
    width: "400px",

    "& svg": {
      height: "400px",
      width: "400px",
    },
  },

  fixedRsp: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "300px",
    width: "300px",

    "& svg": {
      height: "300px",
      width: "300px",
    },
  },

  fixedRspWithBorder: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "300px",
    width: "300px",

    "& svg": {
      height: "300px",
      width: "300px",
    },
  },

  null: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "500px",
  },

  nullRsp: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "300px",
  },
});
