import Lottie from "react-lottie";

import animationDataWave from "../../lotties/dotted-wave-loading.json";
import animationDataButterfly from "../../lotties/butterfly-load.json";
import { CircularProgress } from "@material-ui/core";
import { useStyles } from "./useStyles";

export const LoadingOverlay = ({ open, transaction, type }) => {
  const classes = useStyles();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationDataButterfly,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const waveOptions = {
    loop: true,
    autoplay: true,
    animationData: animationDataWave,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const isTransaction = transaction;    

  if(!open)return <></>

  return (
    // <Backdrop className={classes.backdrop} open={open}>
    <div className={classes.backdrop} >
      <Lottie options={!type ? defaultOptions : waveOptions } height={30} width={60} />
      {isTransaction && (
        <p>
          Processing <CircularProgress color="inherit" />
        </p>
      )}
    </div>
    // </Backdrop>
  );
};
