import firebase from "firebase";
import toast from "react-hot-toast";
import { analytics, auth } from "../config/firebase";

const provider = new firebase.auth.GoogleAuthProvider();

export const googleSignIn = () => {
  auth.signInWithRedirect(provider).then((res) => {
    toast.success("Logged in successfully");

    analytics.setUserId("res.user!.uid");
    analytics.logEvent("user_logged_in");
  });
};

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
};
