import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { auth, db } from "../../config/firebase";
import { withLayout } from "../../Layout/Layout";
import DisplayCard from "../../components/layout/cardLayout/DisplayCard/DisplayCard";

/***
 * @description: link for individual generated art
 * @author: Tailor Vj (@tailorvj)
 * ***/

type PermalinkParams = {
  artworkid: string;
};

const SingleArt = () => {
  const [data, setData] = useState<any>([]);
  const { artworkid } = useParams<PermalinkParams>();

  // pull from db
  useEffect(() => {
    auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        // eslint-disable-next-line
        db.collection("savedImages")
          .where("uid", "==", artworkid)
          .onSnapshot((snapshot) => {
            let newData;
            if (snapshot.docs && snapshot.docs.length > 0) {
              newData = snapshot.docs
                .map((d) => d.data())
                .sort((a, b) => a.created.seconds - b.created.seconds)
                .reverse();
            }
            setData(newData);
          });
      }
    });
    // eslint-disable-next-line
  }, []);

  return <DisplayCard data={data} responsiveClass="containerRsp1" />;
};

export default withLayout(SingleArt);
