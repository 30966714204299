import Moralis from 'moralis';
import { apiPostRequest } from "../utils/ApiRequest";
import {auth} from '../config/firebase'

const APP_ID = process.env.REACT_APP_MORALIS_APP_ID
const SERVER_URL = process.env.REACT_APP_MORALIS_SERVER_URL

const mintImage = apiPostRequest('art/mint');
const cancelImage = apiPostRequest('art/cancelMint');

Moralis.initialize(`${APP_ID}`); // Application id from moralis.io
Moralis.serverURL = `${SERVER_URL}`; //Server url from moralis.io

/****
 * @description:  mint logic
 * @author: @arndom 
 * ****/

export const mintLogic = {
    
    login: async () => {
        await Moralis.Web3.authenticate()
    },

    sendMintRequest: async (artworkid: any) => {
        try {
            //@ts-ignore
            if (ethereum.selectedAddress == null) throw new Error("please reconnect wallet");

            let userToken = await auth.currentUser!.getIdToken(true);
            await mintImage({
              userToken,
              imageUid: artworkid,
              //@ts-ignore
              owner_walletAddr: ethereum.selectedAddress
            });
        }
        catch(e) {
          throw e;
        }
    },
    cancelMintRequest: async (artworkid: any) => {
      try {
          let userToken = await auth.currentUser!.getIdToken(true);
          await cancelImage({
            userToken,
            imageUid: artworkid
          });
      }
      catch(e) {
        throw e;
      }
  },

    notify: async (_txt: any) => {
        alert(`Your NFT was minted in transaction ${_txt}`)
    },

    sendMoney: async (price: any, tokenId: string) => {
      //@ts-ignore  
      const web3 = await Moralis.enableWeb3();
      const gas = 30000;
      let gasApi :any = await (await fetch("https://gasstation-mainnet.matic.network/v2")).json();
      let maxPriorityFeePerGas = web3.utils.toWei(gasApi.fast.maxPriorityFee.toFixed(), 'gwei');
      let maxFeePerGas = web3.utils.toWei(gasApi.fast.maxFee.toFixed(), 'gwei');
      let gasInMatic = web3.utils.fromWei(Number(maxPriorityFeePerGas* gas).toFixed(), 'ether');
      console.log("gas: "+ gas);
      console.log("gasInMatic: "+ gasInMatic);
      return await web3.eth.sendTransaction(
        {
          //@ts-ignore
          from: ethereum.selectedAddress,
          to: process.env.REACT_APP_SAFE_WALLET_ADDRESS,
          value:  Moralis.Units.ETH(`${price}`), 
          maxPriorityFeePerGas: maxPriorityFeePerGas,
          maxFeePerGas: maxFeePerGas,
          gas: Number(gas).toFixed() //6500000
        });
    }


} 