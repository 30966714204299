import React, { memo } from "react";
import Card from "../Card/Card";

/***
 * @description: holds the card component, allowing easier use
 * @author: @arndom
 * ***/

const CardHolder = ({ item, idx }) => {
  return (
    <Card
      key={idx + item.name}
      data={item}
      svg={item.svg}
      name={item.name}
      img={item.img}
      uid={item.uid}
      url={item.url}
      creator={item.creator === undefined ? "" : item.creator}
      creatorUid={item.creatorUid === undefined ? "" : item.creatorUid}
      profile={item.profile === undefined ? "" : item.profile}
      created={item.created}
      upCount={item.upvotes === undefined ? 0 : item.upvotes}
      downCount={item.downvotes === undefined ? 0 : item.downvotes}
    />
  );
};

export default memo(CardHolder);
