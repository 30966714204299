import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import AOS from "aos";
import "aos/dist/aos.css";

import { appRoutes, AppRouteType } from "./routes/app";
import { useMobileHeight } from "./utils/hooks/useMobileHeight";

const LOGROCKET_PROJECT = process.env.REACT_APP_LOGROCKET_PROJECT!;

LogRocket.init(LOGROCKET_PROJECT);
setupLogRocketReact(LogRocket);

function renderRoute(route: AppRouteType, index: number): JSX.Element {
  return (
    <Route key={index} exact path={route.path} component={route.component} />
  );
}

function App() {
  //global init
  AOS.init();

  //fix for 100vh in mobile
  useMobileHeight();

  return (
    <div className="app">
      <Router>
        <Toaster position="top-right" reverseOrder={true} />
        <Switch>
          {appRoutes.map((route, index) => renderRoute(route, index))}
        </Switch>
      </Router>
    </div>
  );
}

export default App;
