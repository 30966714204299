import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useStyles } from "../useStyles";
import * as ROUTES from "../../routes/routes";
import { Button } from "@material-ui/core";
import { analytics } from "../../config/firebase";

export const DesktopFAB = () => {
  const history = useHistory();
  const classes = useStyles();
  const routeTo = (route: string) => {
    history.push(route);
    window.scroll(0, 0);
  };
  const [mode, setMode] = useState("public");
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === ROUTES.GEN_ART) {
      setMode("create");
    } else {
      setMode("public");
    }
  }, [location]);

  if (mode === "create") return <></>;

  return (
    <Button
      className={classes.fabDesktop}
      onClick={() => {
        analytics.logEvent("Click Collect");
        routeTo(ROUTES.GEN_ART);
      }}
    >
      Collect
    </Button>
  );
};
