import { useMediaQuery } from "@material-ui/core";

import React, { FC, Fragment, ComponentType, useState, useEffect } from "react";
import { useLocation } from "react-router";
import toast from "react-hot-toast";
import firebase from "firebase";
import LogRocket from "logrocket";

import * as ROUTES from "../routes/routes";
import { db, auth } from "../config/firebase";
import { useStyles } from "./useStyles";
import BottomNav from "./BottomNav/BottomNav";
import MobileFAB from "./MobileFAB/MobileFAB";
import { DesktopNav } from "./DesktopNav/DesktopNav";
import { MobileNav } from "./MobileNav/MobileNav";
import { googleSignIn } from "../utils/common";

/***
 * @description: layout HOC with top and bottom nav
 * @author: Nabil Alamin (arndom)
 * ***/

export const withLayout =
  <P extends object>(Component: ComponentType<P>): FC<P> =>
  (props) => {

    const classes = useStyles();
    const widthRsp1 = useMediaQuery("(min-width:500px)");
    const location = useLocation();

    const [mode, setMode] = useState("public");
    const [publicProfile, setPublicProfile] = useState<string>("");

    /*** INIT WITH FIREBASE AND LOGROCKET ***/
    useEffect(() => {
      const queryCollection = db.collection("users")

      auth.onAuthStateChanged((userAuth) => {
        if (userAuth) {
          queryCollection.doc(userAuth.uid).set(
            {
              name: userAuth.displayName,
              saved: firebase.firestore.FieldValue.arrayUnion(),
            },
            { merge: true }
          );

          LogRocket.identify(userAuth.uid, {
            name: userAuth.displayName!,
            email: userAuth.email!,
          });
        } else {
          toast.error("Please Sign in!");
          googleSignIn();
        }
      });
    }, []);

    /***  MODE HANDLER ***/
    useEffect(() => {
      const handleModeCollector = () => {
        db.collection("users")
          .doc(location.pathname.split("/collector/").pop())
          .get()
          .then((res) => setPublicProfile(res.data()?.name));
        setMode("collector");
      };
      
      if (location.pathname === ROUTES.PROFILE) {
        setMode("profile");
      } else if (location.pathname === ROUTES.GEN_ART) {
        setMode("create");
      } else if (location.pathname.startsWith("/artwork")) {
        setMode("artwork");
      } else if (location.pathname.startsWith("/mint")) {
        setMode("mint");
      } else if (location.pathname.startsWith("/collector")) {
        handleModeCollector()
      } else {
        setMode("public");
      }
    }, [location]);

    return (
      <>
        <div className={classes.appBar}>
          {/*** DESKTOP NAVBAR ***/}
          {widthRsp1 && 
            (mode === "public"
              ? <DesktopNav title="Collected" />
              : mode === "profile"
              ? <DesktopNav title="Creations" />
              : mode === "create"
              ? <DesktopNav title="Collect" />
              : mode === "collector"
              ? <DesktopNav title={publicProfile === "" ? "" : publicProfile} />
              : <DesktopNav title="Butterfly" />
            )
          }

          {/***  MOBILE NAVBAR ***/}
          {!widthRsp1 && 
            (mode === "public"
            ? <MobileNav title="Explore" />
            : mode === "profile"
            ? <MobileNav title="Me" />
            : mode === "artwork"
            ? <MobileNav title="Butterfly" />
            : mode === "collector"
            ? <MobileNav title={publicProfile === "" ? "" : publicProfile} />
            : mode === "mint"
            ? <MobileNav title='Buy' />
            : <MobileNav title="Collect" />
            ) 
          }
        </div>

        {/*** MOBILE BOTTOMNAV ***/}
        {!widthRsp1 && <BottomNav />}

        {/*** MOBILE FAB ***/}
        {!widthRsp1 && <MobileFAB />}

        {/*** WRAPPED COMPONENT ***/}
        <Component {...(props as P)} />
      </>
    );
  };
