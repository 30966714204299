import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { analytics, auth, db } from "../../config/firebase";
import { useStyles } from "../useStyles";
import * as ROUTES from "../../routes/routes";
import { IconButton } from "@material-ui/core";
import { routeTo, StyledBadge } from "../common";

export const DesktopProfile = () => {
  const history = useHistory();
  const classes = useStyles();
  const [profilePhoto, setProfilePhoto] = useState("");
  const [username, setUsername] = useState("");
  const [itemNo, setItemNo] = useState(0);
  const [hasBlink, setBlink] = useState(false);

  useEffect(() => {
    auth.onAuthStateChanged((userAuth) => {
      if (userAuth) {
        const pic = String(userAuth.photoURL);
        setProfilePhoto(pic);
        const name = String(userAuth.displayName);
        setUsername(name);

        const collectionRef = db
          .collection("savedImages")
          .where("creatorUid", "==", userAuth.uid);
        collectionRef.onSnapshot((snapshot) => {
          setItemNo(snapshot.docs.length);
          setBlink(true);
        });
      }
    });
  }, []);

  useEffect(() => {
    if (hasBlink) {
      setTimeout(() => {
        setBlink(false);
      }, 1100);
    }
  }, [hasBlink]);

  const handleClick = () => {
    analytics.logEvent("Click own Profile");
    routeTo(ROUTES.PROFILE, history);
  };

  if (itemNo === 0) {
    return (
      <div onClick={handleClick} className={classes.fabHolderDesktop}>
        <div>
          <p className={classes.fabUserName}>{username}</p>
        </div>
        <IconButton onClick={handleClick}>
          <img
            src={profilePhoto}
            alt="profile"
            className={classes.userProfile}
          />
        </IconButton>
      </div>
    );
  }

  return (
    <div onClick={handleClick} className={classes.fabHolderDesktop}>
      <div>
        <p className={classes.fabUserName}>{username}</p>
      </div>

      <IconButton onClick={handleClick}>
        <StyledBadge
          className={hasBlink ? classes.blink : ""}
          max={999}
          badgeContent={itemNo}
        />
        <img src={profilePhoto} alt="profile" className={classes.userProfile} />
      </IconButton>
    </div>
  );
};
