import { Button, IconButton, useMediaQuery } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import Web3 from "web3";
import toast from "react-hot-toast";
import { Close } from "@material-ui/icons";
import Moralis from "moralis";

import DisplayCard from "../../components/layout/cardLayout/DisplayCard/DisplayCard";
import { analytics, auth, db } from "../../config/firebase";
import { withLayout } from "../../Layout/Layout";
import { mintLogic } from "../../constants/mintLogic";
import { RegularDialog } from "../../components/RegularDialog/RegularDialog";
import * as NETWORKS from "../../constants/supportedNetworks";
import * as ROUTES from "../../routes/routes";
import { LoadingOverlay } from "../../components/overlays/LoadingOverlay/LoadingOverlay";
import { useStyles } from "./useStyles";

/****
 * @description: mint page after on click event
 * @author: @arndom (Nabil Alamin)
 * ****/

type RouteParams = {
  artworkid: string;
};

declare let window: any;

const MintArt = () => {
  const [data, setData] = useState<any>([]);
  const { artworkid } = useParams<RouteParams>();
  const history = useHistory();
  const [error, setError] = useState(false);

  // pull from db
  useEffect(() => {
    auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        db.collection("savedImages")
          .where("creatorUid", "==", userAuth.uid)
          .where("uid", "==", artworkid)
          .onSnapshot((snapshot) => {
            let newData;
            if (snapshot.docs && snapshot.docs.length > 0) {
              newData = snapshot.docs
                .map((d) => d.data())
                .sort((a, b) => a.created.seconds - b.created.seconds)
                .reverse();
            }
            if (newData !== undefined) {
              setData(newData);
            } else {
              history.push(ROUTES.PROFILE);
            }
          });
      }
    });
  }, [artworkid, history]);

  const widthRsp = useMediaQuery("(min-width:500px)");
  const classes = useStyles();

  const web3 = new Web3(window.ethereum);

  const [acc, setAcc] = useState(false);
  const [price, setPrice] = useState(0);
  const [isGasHigh, setGasHigh] = useState(false);

  const [noWalletDialog, setNoWalletDialog] = useState(false);
  const [mintDisabled, setMintDisabled] = useState(false);
  const [loadOverlay, setLoadOverlay] = useState(false);

  const handleWalletDialogClose = () => {
    analytics.logEvent("metamask_missing");
    setNoWalletDialog(false);
    window.location.reload();
  };
  const handleWalletDialogOpen = () => {
    setNoWalletDialog(true);
  };

  const isWalletInstalled = () => {
    if (typeof window.ethereum !== "undefined") {
      console.log("MetaMask is installed!");
      return true;
    } else {
      console.log("MetaMask Not installed");
      return false;
    }
  };

  const isWalletConnected = () => {
    web3.eth.getAccounts(function (err, accounts) {
      if (err !== null) console.error("An error occurred: " + err);
      else if (accounts.length === 0) setAcc(false);
      else {
        setAcc(true);
      }
    });
  };

  const connectWallet = () => {
    analytics.logEvent("connect_wallet");
    if (isWalletInstalled()) {
      mintLogic.login();
    } else {
      handleWalletDialogOpen();
    }
  };

  const toHex = (num) => {
    return "0x" + num.toString(16);
  };

  const getNetwork = async () => {
    const chainID = await web3.eth.net.getId();
    console.log(chainID);
    return chainID;
  };

  const ENV_NETWORK_CHOICE = process.env.REACT_APP_NETWORK_ENV;

  const _supportedNetworks = {
    POLYGON_TESTNET: 80001,
    POLYGON_MAINNET: 137,
  };

  const _mint = async () => {
    setMintDisabled(true);
    setLoadOverlay(true);

    try {
      await mintLogic.sendMintRequest(artworkid);
      await mintLogic.sendMoney(price, artworkid);
      setMintDisabled(true);
      setLoadOverlay(false);
      history.push(ROUTES.PROFILE);
      analytics.logEvent("mint_payment_successful");
      toast(
        (t) => (
          <span style={{ textAlign: "center" }}>
            Transaction Successful. Minting...
            <IconButton
              onClick={() => toast.dismiss(t.id)}
              style={{ marginLeft: "10px" }}
            >
              <Close style={{ fill: "white" }} />
            </IconButton>
          </span>
        ),
        {
          position: "top-center",
          duration: 120000,
          icon: "🦋",
          style: {
            background:
              "linear-gradient(90deg, hsla(323, 97%, 75%, 1) 0%, hsla(212, 79%, 58%, 1) 100%)",
            color: "whitesmoke",
            fontSize: "1.2rem",
            fontWeight: 600,
          },
        }
      );
    } catch (err: any) {
      analytics.logEvent("mint_payment_failed");
      console.log("Error minting: " + JSON.stringify(err));
      if ([4001, 4100, 4200, 4900, 4901].indexOf(err.code) > -1) {
        await mintLogic.cancelMintRequest(artworkid);
      }
      setError(true);
      setMintDisabled(false);
      setLoadOverlay(false);
    }
  };

  const addNetworkToWallet = async (chain) => {
    const params = {
      chainId: toHex(chain.chainId),
      chainName: chain.name,
      nativeCurrency: {
        name: chain.nativeCurrency.name,
        symbol: chain.nativeCurrency.symbol,
        decimals: chain.nativeCurrency.decimals,
      },
      rpcUrls: chain.rpc,
      blockExplorerUrls: [
        chain.explorers && chain.explorers.length > 0 && chain.explorers[0].url
          ? chain.explorers[0].url
          : chain.infoURL,
      ],
    };

    await web3.eth.getAccounts((error, accounts) => {
      window.ethereum
        .request({
          method: "wallet_addEthereumChain",
          params: [params, accounts[0]],
        })
        .then((res) => {
          analytics.logEvent("metamask_added_polygon");
          _mint();
        })
        .catch((err) => {
          analytics.logEvent("metamask_addnet_failed");
          console.log(err.message);
        });
    });
  };

  const changeWalletNetwork = async (chainID) => {
    await window.ethereum
      .request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: toHex(chainID) }], // chainId must be in hexadecimal numbers
      })
      .then((res) => {
        analytics.logEvent("metamask_switchto_polygon");
        window.location.reload();
      })
      .catch(async (error) => {
        analytics.logEvent("metamask_switchnet_failed");
        //@ts-ignore
        console.log(error.code);
        //@ts-ignore
        if (error.code === 4902) {
          if (ENV_NETWORK_CHOICE === "mumbai") {
            await addNetworkToWallet(NETWORKS.POLYGON_TESTNET);
          } else if (ENV_NETWORK_CHOICE === "polygon") {
            await addNetworkToWallet(NETWORKS.POLYGON_MAINNET);
          }
        }
      });
  };

  //check wallet is connected at intervals
  //TODO: Get rid of this somehow
  useEffect(() => {
    const interval = setInterval(
      () => isWalletInstalled() && isWalletConnected(),
      1000
    );
    return () => clearInterval(interval);

    //eslint-disable-next-line
  }, []);

  const fetchPrice = async () => {
    const collection = db.collection("prices");

    const butterflyInfo = (await collection.doc("butterfly").get()).data();
    const maticInfo = (await collection.doc("matic").get()).data();

    const _price = Number(
      (butterflyInfo!.price_usd / maticInfo!.rate).toFixed(4)
    );
    setPrice(_price);
  };

  const fetchGas = async () => {
    //@ts-ignore
    const web3 = await Moralis.enableWeb3();
    const gas = 30000;

    let gasApi: any = await (
      await fetch(
        "https://gpoly.blockscan.com/gasapi.ashx?apikey=key&method=pendingpooltxgweidata"
      )
    ).json();
    let gasPrice = web3.utils.toWei(
      gasApi.result.rapidgaspricegwei.toFixed(),
      "gwei"
    );
    let gasInMatic = web3.utils.fromWei(
      Number(gasPrice * gas).toFixed(),
      "ether"
    );

    //max gas from db
    const _isGasHigh = gasInMatic >= 0.2 ? true : false;

    if (_isGasHigh) {
      analytics.logEvent("gasishigh");
      setGasHigh(true);
      setMintDisabled(true);
    } else {
      analytics.logEvent("gasisnormal");
      setGasHigh(false);
      setMintDisabled(false);
    }
  };

  //check price
  useEffect(() => {
    fetchPrice();
  }, []);

  useEffect(() => {
    fetchGas();
  }, []);

  const mintOnClick = async () => {
    analytics.logEvent("clicked_mint");
    const network = await getNetwork();

    if (ENV_NETWORK_CHOICE === "mumbai") {
      if (network === _supportedNetworks.POLYGON_TESTNET) {
        _mint();
      } else {
        await changeWalletNetwork(_supportedNetworks.POLYGON_TESTNET);
      }
    } else if (ENV_NETWORK_CHOICE === "polygon") {
      if (network === _supportedNetworks.POLYGON_MAINNET) {
        _mint();
      } else {
        await changeWalletNetwork(_supportedNetworks.POLYGON_MAINNET);
      }
    }
  };

  return (
    <div className={!widthRsp ? classes.container : ""}>
      {error && (
        <div
          style={{
            marginTop: "2rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: "red",
          }}
        >
          {analytics.logEvent("minterror")}
          <p>Something went wrong...</p>
          <p style={{ color: "black" }}>Please try again later</p>
        </div>
      )}

      <div className={widthRsp ? classes.main : classes.mainResp}>
        <RegularDialog
          handleClose={handleWalletDialogClose}
          open={noWalletDialog}
          title="Open or install MetaMask ❗"
          content="How to install a wallet"
        />

        <LoadingOverlay type={false} open={loadOverlay} transaction={true} />

        <DisplayCard data={data} />

        {isGasHigh ? (
          <div>
            <p>Polygon network status: busy</p>
            <p>Please try again later</p>
          </div>
        ) : (
          <div className={classes.dataHolder}>
            {!acc && (
              <Button className={classes.connectButton} onClick={connectWallet}>
                Connect Wallet
              </Button>
            )}

            <div className={classes.priceInfo}>
              <p>ButterFly Price: </p>
              <p style={{ paddingLeft: "5px" }}>{price} MATIC</p>
            </div>

            {acc && (
              <Button
                className={classes.mintButton}
                onClick={mintOnClick}
                disabled={mintDisabled}
              >
                Mint Butterfly
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default withLayout(MintArt);
