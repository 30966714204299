import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
    appBar: {
      top: 0,
      position: "fixed",
      zIndex: 50,
      background: "white",
      width: "100%",
      height: "80px",
      padding: "16px",
  
      "@media (max-width: 767px)": {
        // height: "60px",
      },
  
      boxShadow: "0 0 10px 0 rgba(0, 0, 0, .1)",
  
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  
    bottombar: {
      bottom: 0,
      position: "fixed",
      zIndex: 50,
      background: "white",
      width: "100%",
      height: "4em",
  
      boxShadow: "0 -5px 10px 0 rgba(0, 0, 0, .1  )",
  
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      padding: "10px 0",
  
      "& .MuiSvgIcon-root": {
        fill: "rgba(89, 52, 175, 0.6)",
      },
  
      "& .MuiIconButton-label": {
        display: "flex",
        flexDirection: "column",
        color: "rgba(89, 52, 175, 0.6)",
        fontSize: "12px",
      },
    },
  
    activeIcon: {
      "& .MuiSvgIcon-root": {
        fill: "#4721BF",
      },
  
      "& .MuiIconButton-label": {
        color: "#4721BF",
      },
    },
  
    fabHolderDesktop: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "8px",
      border: "1px solid #D7D4D4",
      height: "100%",
      paddingLeft: "12px",
      paddingRight: "8px",
      marginRight: "12px",
      textTransform: "none",
      cursor: 'pointer',
  
      "&.MuiButton-root:hover": {
        background: "#E9FFFC;",
      },
    },
  
    fabUserName: {
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: 400,
    },
  
    fabCountInfo: {
      display: "flex",
      alignItems: "center",
  
      "& img": {
        marginRight: "4px",
      },
  
      "& p": {
        fontFamily: "Poppins",
        fontSize: "12px",
        fontWeight: 400,
      },
    },
  
    fabDesktop: {
      cursor: "pointer",
      textTransform: "none",
  
      backgroundColor: "rgba(71, 33, 191, 1)",
      color: "white",
  
      "&.MuiButton-root:hover": {
        background: "rgba(71, 33, 191, 1)",
        color: "white",
      },
  
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
  
      width: "85px",
      height: "100%",
  
      borderRadius: "5px",
      fontSize: ".85rem",
      fontWeight: 500,
    },
  
    fab: {
      bottom: 0,
      right: 0,
      position: "fixed",
      zIndex: 50,
      margin: "0 20px 20px",
    },
  
    fabRsp1: {
      bottom: 60,
      right: 0,
      position: "fixed",
      zIndex: 50,
      margin: "0 10px 20px",
    },
  
    logo: {
      width: "136px",
      height: "100%",
      cursor: "pointer",
      zIndex: 122,
    },
  
    logoRsp: {
      width: "88px",
      height: "100%",
      zIndex: 122,
      cursor: "pointer",
    },
  
    logoImg: {
      height: "100%",
      width: "100%",
      objectFit: "contain",
    },
  
    butterFlyIcon: {
      background: "rgba(71, 33, 191, 1)",
      color: "white",
      textTransform: "none",
      display: "flex",
      alignItems: "center",
      width: "101px",
      height: "48px",
      borderRadius: "80px",
      fontSize: ".85rem",
      fontWeight: 500,
      boxShadow: "-1px -1px 20px 2px rgba(0, 0, 0, .18) ",
      padding: "0 3.5rem",
  
      "&.MuiButton-root:hover": {
        background: "rgba(71, 33, 191, 1)",
        color: "white",
      },
  
      "& img": {
        marginRight: ".35rem",
      },
    },
  
    nav: {
      textAlign: "center",
      fontWeight: 400,
      color: "rgba(26, 4, 77, 1)",
      textTransform: "uppercase",
      letterSpacing: '0.02857em',

      width: '100%',
      position: 'absolute',
      zIndex: -1
    },
  
    userProfile: {
      borderRadius: "100%",
      width: "36px",
      opacity: "1 !important",
    },
  
    gone: {
      display: "none",
    },
  
    blink: {
      animation: `$blink-animation 1s  forwards`,
    },
  
    "@keyframes blink-animation": {
      to: {
        visibility: "hidden",
      },
    },

    profile:{
      display: 'flex',
      height: '100%'
    },

    mobileNav:{
      color: "rgba(26, 4, 77, 1)",
      fontSize: "16px",
      fontWeight: 500,
      flex: 2,
      textAlign: "center",
      width: "100%",
    }
  });