import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { useStyles } from "./useStyles";

export const RegularDialog = ({ handleClose, open, title, content }) => {
  const classes = useStyles();

  return (
    <Dialog onClose={handleClose} open={open} className={classes.root}>
      <h3>{title}</h3>
      <a
        href="https://us5.campaign-archive.com/?u=a5173e11d480355f0a9fc8fc2&id=f3ec7fb219"
        target="_blank"
        rel="noreferrer"
      >
        {content}
      </a>
    </Dialog>
  );
};
