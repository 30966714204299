import React, { useEffect, useState } from "react";

import { auth } from "../../config/firebase";
import { withLayout } from "../../Layout/Layout";
import DisplayCard from "../../components/layout/cardLayout/DisplayCard/DisplayCard";
import { LoadingOverlay } from "../../components/overlays/LoadingOverlay/LoadingOverlay";
import { feedVariables } from "../../utils/feedVariables";
// import { useHorizontalScroll } from "../../utils/hooks/useHorizontalScroll";

/***
 * @description: user personal collection
 * @author: Nabil Alamin (@arndom)
 * ***/

const UserProfile = () => {

  const [data, setData] = useState<any>([]);
  const [loadOverlay, setLoadOverlay] = useState(false);
  const [user, setUser] = useState<any>({});

  const collectionRef = feedVariables.feedCollection;
  const itemsPerPage = feedVariables.itemsPerPage;
  const orderField = feedVariables.orderField;

  const lengthOffset = itemsPerPage -1
  const [lastItemIndex, setlastItemIndex] = useState(lengthOffset)
  const [nextExists, setNextExists] = useState(false)

  const fetchData = (query: any) => {
    auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        setLoadOverlay(true);
        query.onSnapshot((snapshot) => {
          const _data = snapshot.docs.map((doc) => doc.data());
          const reData = data.concat(_data)
          
          console.log(reData.length)
          reData.length % 12 === 0 ? setNextExists(true) : setNextExists(false)

          setData(reData);
          setlastItemIndex(reData.length - 1)
          setLoadOverlay(false);
        });
      }
    });
  };

  const nextPage = (uid, last) => {
    const query = collectionRef
      .where("creatorUid", "==", uid)
      .orderBy(orderField, "desc")
      .startAfter(last[orderField])
      .limit(itemsPerPage);
    fetchData(query);
  };


  useEffect(() => {
    auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        setUser(userAuth);
        setLoadOverlay(true);
        const query = collectionRef
          .where("creatorUid", "==", userAuth.uid)
          .orderBy(orderField, "desc")
          .limit(itemsPerPage);
        fetchData(query);
      }
    });
    //eslint-disable-next-line
  }, []);

  const handleNext = () =>
    nextExists &&
    user.uid &&
    data[lastItemIndex] &&
    nextPage(user.uid, data[lastItemIndex]);

  // useHorizontalScroll(handleNext);

   useEffect(() => {
     const hScroll = (e) => {
       const element = document.getElementById("feed");
       if (feedVariables.isBottom(element)) handleNext();
     };

     document.addEventListener("scroll", hScroll);

     return () => {
       document.removeEventListener("scroll", hScroll);
     };
   });

  return (
    <div>
      <DisplayCard
        data={data}
        responsiveClass="containerRspFeed"
        id="feed"
      />
  
      <LoadingOverlay type={true} open={loadOverlay} transaction={false} />
    </div>
  );
};

export default withLayout(UserProfile);
