import { useHistory } from "react-router-dom";
import { useStyles } from "../useStyles";
import * as ROUTES from "../../routes/routes";
import { MobileProfile } from "../MobileProfile/MobileProfile";
import logo from "../../assets/svgs/Navbar/WIBT-logo.svg";
import { routeTo } from "../common";


interface INav{
    title: string
}

export const MobileNav = ({title}:INav) => {
    const classes = useStyles();
    const history = useHistory();
    
    return (
      <>
        <div
          onClick={() => routeTo(ROUTES.PUBLIC_FEED, history)}
          className={classes.logoRsp}
        >
          <img alt="logo" src={logo} className={classes.logoImg} />
        </div>

        <>
          <p className={classes.mobileNav}>{title}</p>

          <MobileProfile />
        </>
      </>
    );
  }
  