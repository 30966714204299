const HOST = process.env.REACT_APP_API_HOST;

const requestOptionsOf = (data) => ({
	method: 'POST',
	headers: { 'Content-Type': 'application/json' },
	body: JSON.stringify(data)
});

export const apiPostRequest = (api) => async (data) => { 
	const response = await fetch(`${HOST}/api/${api}`, requestOptionsOf({data}));
	let res = await response.json();
	if (response.ok)
		return res;
	else 
		throw res; 
}