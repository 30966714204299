import { db } from "../config/firebase";

export const feedVariables = {
  feedCollection: db.collection("savedImages"),
  itemsPerPage: 12,
  displayedItems: 12,
  orderField: "created",

  isBottom: (element) => {
    if(element){
      return Math.round(element.getBoundingClientRect().bottom) <= window.innerHeight;
    }
  },
};