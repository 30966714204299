import React from "react";
import { withLayout } from "../../Layout/Layout";
import { styles } from "./styles";

const NotFound_404 = () => {
  return (
    <div style={styles.root}>
      <h3 style={{ fontWeight: 400 }}>404 | Page Not Found</h3>
    </div>
  );
};

export default withLayout(NotFound_404);
