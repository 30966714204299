import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { analytics } from "../../config/firebase";
import * as ROUTES from "../../routes/routes";
import { useStyles } from "../useStyles";
import butterFly from "../../assets/images/butterFly.svg";
import { routeTo } from "../common";

const MobileFAB = () => {
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();

  const [mode, setMode] = useState("public");

  useEffect(() => {
    if (location.pathname === ROUTES.PROFILE) {
      setMode("profile");
    } else if (location.pathname === ROUTES.GEN_ART) {
      setMode("create");
    } else if (location.pathname.startsWith("/artwork")) {
      setMode("artwork");
    } else if (location.pathname.startsWith("/mint")) {
      setMode("mint");
    } else if (location.pathname.startsWith("/collector")) {
      setMode("collector");
    } else {
      setMode("public");
    }
  }, [location]);

  return (
    <>
      {mode !== "create" && mode !== "mint" && (
        <div
          className={classes.fabRsp1}
          onClick={() => {
            analytics.logEvent("Click Collect");
            routeTo(ROUTES.GEN_ART, history);
          }}
        >
          <Button className={classes.butterFlyIcon}>
            <img src={butterFly} alt="g " />
            Collect
          </Button>
        </div>
      )}
    </>
  );
};

export default MobileFAB;
