import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Redirect } from "react-router-dom";
import { LoadingOverlay } from "../../components/overlays/LoadingOverlay/LoadingOverlay";
import { auth } from "../../config/firebase";
import { withLayout } from "../../Layout/Layout";
import { googleSignIn } from "../../utils/common";
import { styles } from "./styles";

const ProfileRedirect = () => {
  //get uid to enable redirect
  const [uid, setUid] = useState("");

  useEffect(() => {
    if (window.location.pathname === "/me") {
      auth.onAuthStateChanged((user) => {
        if (user) {
          setUid(user!.uid);
        } else {
          toast.error("Please Sign in!");
          googleSignIn();
        }
      });
    }
  }, []);

  if (uid === "")
    return (
      <div style={styles.redirect}>
        <div>
          <p style={{ marginBottom: "-2rem" }}>Redirecting</p>
          <LoadingOverlay open={true} type={true} transaction={false} />
        </div>
      </div>
    );

  return <Redirect to={`/collector/${uid}`} />;
};

export default withLayout(ProfileRedirect);
