import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  container: {
    marginTop: "70px",
  },

  main: {
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "center",
    alignItems: "center",
  },

  mainResp: {
    display: "flex",
    flexDirection: "column-reverse",
    justifyContent: "center",
    alignItems: "center",
  },

  dataHolder: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "1rem",
  },

  connectButton: {
    backgroundColor: "purple !important",
    color: "white",
    margin: "1em",
    fontWeight: 600,
  },

  fundButton: {
    backgroundColor: "tomato !important",
    color: "white",
    margin: "1em",
    fontWeight: 600,
  },

  priceInfo: {
    display: "flex",
    margin: "1em",
  },

  mintButton: {
    backgroundColor: "#49cc49 !important",
    color: "white",
    margin: "1em",
    padding: "1em",
    fontWeight: 600,
    fontSize: "1rem",
    // marginBottom: "-50px"

    "&.MuiButton-root.Mui-disabled": {
      backgroundColor: "grey !important",
    },
  },
});
