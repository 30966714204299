import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  mapHolder: {
    background: "white",
    margin: "1rem 1rem 1.25rem",
    borderRadius: "10px",
    boxShadow: "-1px -1px 20px 2px rgba(0, 0, 0, .18)",

    " & a": {
      textDecoration: "none",
    },
  },

  artProfile: {
    display: "flex",
    justifyContent: "space-between",
    padding: ".5rem",
    alignItems: "center",
  },

  artHolder: {
    height: "400px",
    width: "400px",
  },

  artHolderRsp1: {
    height: "300px",
    width: "300px",
  },

  shoppingCart: {
    background: "rgba(173, 255, 255, 1)",
    color: "rgba(26, 4, 77, 1)",
    textTransform: "none",
    display: "flex",
    alignItems: "center",
    width: "68px",
    height: "40px",
    borderRadius: "12px",
    fontSize: ".85rem",
    fontWeight: 500,

    "&.MuiButton-root:hover": {
      background: "rgba(173, 255, 255, 1)",
      color: "rgba(26, 4, 77, 1)",
    },

    "& .MuiSvgIcon-root": {
      fill: "rgba(37, 24, 24, 1)",
      width: "18px",
      // marginRight: ".7rem",
    },
  },

  nameTitle: {
    display: "flex",
    justifyContent: "space-around",
    padding: ".5rem",
    borderTop: "solid 1px rgb(230,230,230) !important",
    backgroundColor: "rgb(250,250,250)",
  },

  artButtons: {
    display: "flex",
    justifyContent: "space-around",
    padding: ".5rem",
    // height: "65px"
  },

  typography: {
    padding: "20px",
    color: "#b1b1b1",
  },

  share: {
    padding: "20px",
  },

  counter: {
    position: "absolute",
    fontSize: "14px",
    marginLeft: "34px",
    marginTop: "12px",
    fontWeight: 600,
  },

  deleteOutline: {
    fill: "#8773A1",
    width: "25px",
    height: "24px",
  },

  butterflyName: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "black",
  },

  sharedAppStyle: {
    margin: "0 10px",
  },
});
