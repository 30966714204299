import React, { useCallback, useEffect, useRef, useState } from "react";
import { withStyles, useMediaQuery, Button } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import toast from "react-hot-toast";
import { useSwipeable } from "react-swipeable";
import firebase from "firebase";
import { Bars } from "svg-loaders-react";

import { BaseInsect } from "../../components/layout/BaseInsect/BaseInsect";
import { auth, db, analytics } from "../../config/firebase";
import { withLayout } from "../../Layout/Layout";
import upvote from "../../assets/images/upvote.svg";
import downvote from "../../assets/images/downvote.svg";
import { apiPostRequest } from "../../utils/ApiRequest";
import checkIcon from "../../assets/svgs/collect/c-check 1.svg";
import { useHistory } from "react-router-dom";
import * as ROUTES from "../../routes/routes";
import { useStyles } from "./useStyles";

const getImage = apiPostRequest("art/getImage");
const saveImage = apiPostRequest("art/saveImage");

const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "ffffff",
    color: "rgba(255, 255, 255, 0.87)",
    boxShadow: "0px 0px 0px 2px rgb(0 0 0 / 10%)",
    fontSize: ".75rem",
  },
}))(Tooltip);

/***
 * @description:  Main landing page for generation after firebase authentication
 * @author: Nabil Alamin (arndom @github)
 * ***/

const Collect = () => {
  const classes = useStyles();
  const [state, setState] = useState<any>(null);
  const history = useHistory();
  const [hasCollected, setHasCollected] = useState(true)

  /* RESPONSIVE BREAKPOINTS */
  const widthRsp = useMediaQuery("(min-width:750px)");
  const widthRsp1 = useMediaQuery("(min-width:500px)");
  
  /* SWIPE ACTION HANDLERS */
  const handlers = useSwipeable({
    onSwipedLeft: () => randomGen(false),
    onSwipedRight: () => randomGen(true),
    preventDefaultTouchmoveEvent: true,
  });

  /* SWIPE REFERENCE */
  const swipeRef = useRef();
  const refPassthrough = (el) => {
    handlers.ref(el);
    swipeRef.current = el;
  };

  /* ART REFERENCE */
  const ref = useRef<HTMLDivElement>(null);
  const [loader, setLoader] = useState<Boolean>(true);

  /* INIT GENERATE & FIREBASE */
  useEffect(() => {
    auth.onAuthStateChanged((userAuth) => {
      if (userAuth) {
        (async () => {
          setState(await fetchState());
          setLoader(false);
        })();

        db.collection("users").doc(userAuth.uid).set(
          {
            name: userAuth.displayName,
            saved: firebase.firestore.FieldValue.arrayUnion(),
          },
          { merge: true }
        );
      }
    });
  }, []);

  const collectImage = useCallback(
    async (name) => {
      setLoader(true);
      let userToken = await auth.currentUser!.getIdToken(true);
      await saveImage({ userToken });
      analytics.logEvent("Saved Artwork");
      setState(await fetchState());
      setLoader(false);

      //toast
      const toastContent = (id) => (
        <div className={classes.toastContent}>
          <img src={checkIcon} alt="check-icon" />
          You added {name} to your Collection!
        </div>
      );
      toast((t) => toastContent(t.id), {
        position: widthRsp1 ? "top-right" : "top-center",
        className: `${classes.successToast}`,
      });
    },
    // eslint-disable-next-line
    [ref, widthRsp1]
  );

  const randomGen = async (like) => {
    if (like) {
      collectImage(state.name);
    } else {
      analytics.logEvent("Dismissed Artwork");
      toast.success("Next", {
        className: `${classes.regularToast}`,
      });
      setLoader(true);
      setState(await fetchState());
      setLoader(false);
    }
  };

  const fetchState = async () => {
    let res;
    try {
      if (!auth.currentUser) return "";
      let userToken = await auth.currentUser!.getIdToken(true);
      res = await getImage({ userToken });
    } catch (e) {
      console.log("ERROR: " + e);
    }
    return res.data;
  };

  const fetchCollected = (query: any) => {
    auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        query.onSnapshot((snapshot) => {
          const _data = snapshot.docs.map((doc) => doc.data());
          const count = _data?.length
          count > 0 ? setHasCollected(true) : setHasCollected(false)
        });
      }
    });
  };

  /* FETCH COLLECTED CALL */
  useEffect(() => {
    auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        const query = db.collection("savedImages")
          .where("creatorUid", "==", userAuth.uid)
        fetchCollected(query);
      }
    });
  },[])

  return (
    <div className={widthRsp ? classes.page : classes.pageRsp}>
      {loader ? (
        /* LOADING SVG */
        <div
          className={
            widthRsp1 ? classes.loaderContainer : classes.loaderContainerRsp1
          }
        >
          <p
            style={{
              width: "70%",
              marginBottom: "2rem",
              textAlign: "center",
              fontWeight: 500,
            }}
          >
            {" "}
            Generating: please be patient ⏳
          </p>
          <Bars style={{ fill: "rgba(135, 132, 151, .8)" }} />
        </div>
      ) : (
        <>
          {/* GENERATED ART */}
          <div
            className={widthRsp1 ? classes.container : classes.containerRsp1}
            ref={ref}
          >
            <div
              className={widthRsp1 ? classes.artHolder : classes.artHolderRsp1}
              {...handlers}
              ref={refPassthrough}
            >
              {<BaseInsect img="" svg={state.svg} uid="" url="" key={2} />}
            </div>
          </div>

          {/* BUTTON GROUP */}
          <div
            className={
              widthRsp ? classes.buttonHolder : classes.buttonHolderRsp
            }
          >
            <LightTooltip title="Dislike" placement="top">
              <Button
                onClick={() => randomGen(false)}
                className={classes.buttonNext}
              >
                <img src={downvote} alt="downvote" />
                <p>Next</p>
              </Button>
            </LightTooltip>

            <LightTooltip title="Like" placement="top">
              <Button
                onClick={() => randomGen(true)}
                className={classes.buttonFav}
              >
                <img src={upvote} alt="upvote" />
                <p>Save</p>
              </Button>
            </LightTooltip>
          </div>

          {/* HELP MESSAGE */}
          <div className={classes.helper}>
            {hasCollected ?
              <>
              <p>Click your pic and name at the top right to</p>
              <p
                onClick={() => history.push(ROUTES.PROFILE)}
                style={{ cursor: "pointer", textDecoration: 'underline' }}
              >
                visit your butterfly collection
              </p>
            </>
            :
            <>
              <p>Click the save button above to add to</p>
              <p
                onClick={() => history.push(ROUTES.PROFILE)}
                style={{ cursor: "pointer", textDecoration: 'underline' }}
              >
                your collection
              </p>
            </>}
          </div>
        </>
      )}
    </div>
  );
};

export default withLayout(Collect);
