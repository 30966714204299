import React, { useEffect } from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { auth } from "../../config/firebase";
import { useHistory } from "react-router-dom";

import * as ROUTES from "../../routes/routes";
import wibt_logo from "../../assets/svgs/landing_desktop/WIBT-logo.svg";
import war_logo from "../../assets/svgs/landing_desktop/W-A-R-logo.svg";
import g_sign from "../../assets/svgs/landing_desktop/GSign.svg";
import unchecked from "../../assets/svgs/landing_desktop/unchecked.svg";
import checked from "../../assets/svgs/landing_desktop/checked.svg";
import rainB from "../../assets/svgs/landing_desktop/rainbow+bfs.svg";
import rainMB2 from "../../assets/svgs/landing_mobile/mobile-bf2.svg";
import rainMB1 from "../../assets/svgs/landing_mobile/mobile-rainbow+bfs.svg";
import { useStyles } from "./useStyles";
import { googleSignIn } from "../../utils/common";

/***
 * @description: Landing page displaying site name and call to action
 * @author: Nabil Alamin (arndom @github)
 * ***/

const Home = () => {
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    auth.onAuthStateChanged((userAuth) => {
      if (userAuth) {
        history.push(ROUTES.GEN_ART);
      }
    });
    //eslint-disable-next-line
  }, []);

  const [policyState, setState] = React.useState(true);

  const handleChange = () => {
    setState((prevState) => !prevState);
  };

  const openPolicy = () => {
    window.open("https://www.artwork.rocks/privacy-policy/", "_blank");
  };

  return (
    <div className={classes.page}>
      <div>
        <img alt="wibt_logo" src={wibt_logo} />
      </div>

      <div className={classes.rainbow}>
        <img alt="rainbow" src={rainB} />
      </div>
      <div className={classes.rainbowMobile1}>
        <img alt="rainbow_mobile1" src={rainMB1} />
      </div>
      <div className={classes.rainbowMobile2}>
        <img alt="rainbow_mobile2" src={rainMB2} />
      </div>

      <div className={classes.content}>
        <div className={classes.warLogo}>
          <img alt="war_logo" src={war_logo} />
          <p>Women.Artwork.Rocks</p>
        </div>

        <p className={classes.underTag}>
          The Metaverse could use a female touch
        </p>

        <div className={classes.tagLine}>
          <p>Collect beautiful butterflies.</p>
          <p>Mint them as NFT</p>
        </div>

        <button
          disabled={!policyState}
          className={classes.googleSign}
          onClick={googleSignIn}
        >
          <img alt="g_logo" src={g_sign} />
          <p>Sign in with Google</p>
        </button>

        <div className={classes.policyHolder}>
          <FormControlLabel
            className={classes.policy}
            control={
              <Checkbox
                checked={policyState}
                name="privacy-check"
                onChange={handleChange}
                checkedIcon={<img src={checked} alt="checked" />}
                icon={<img src={unchecked} alt="unchecked" />}
                disableRipple
              />
            }
            label=""
          />
          <p className={classes.policyText} onClick={openPolicy}>
            I agree to the privacy policy
          </p>
        </div>
      </div>
    </div>
  );
};

export default Home;
