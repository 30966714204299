
import {
  Collect,
  Home,
  MintArt,
  NotFound_404,
  ProfileRedirect,
  PublicFeed,
  PublicUserProfile,
  SingleArt
} from '../pages';

import * as ROUTES from './routes'
export interface AppRouteType {
  path: string;
  component: React.ComponentType;
}

export const appRoutes: AppRouteType[] = [
  {
    path: ROUTES.LANDING,
    component: Home,
  },
  {
    path: ROUTES.GEN_ART,
    component: Collect,
  },
  {
    path: ROUTES.PROFILE,
    component: ProfileRedirect,
  },
  {
    path: ROUTES.PUBLIC_FEED,
    component: PublicFeed,
  },
  {
    path: ROUTES.PERMALINK,
    component: SingleArt,
  },
  {
    path: ROUTES.USER_PUBLIC,
    component: PublicUserProfile,
  },
  {
    path: ROUTES.MINT_ART,
    component: MintArt,
  },
  {
    path: '*',
    component: NotFound_404,
  },
];
