import { useMediaQuery } from "@material-ui/core";
import React, { memo } from "react";
import CardHolder from "../CardHolder/CardHolder";

/*****
 * @description:  Display card holder
 * @author: @arndom
 * ****/

interface props {
  data: any;
  responsiveClass?: any;
  id?: any;
}

const DisplayCard = (props: props) => {
  const widthRsp1 = useMediaQuery("(min-width:500px)");

  return (
    <div className={"page"}>
      <div
        className={widthRsp1 ? "container" : props.responsiveClass}
        id={props.id}
      >
        {props.data !== undefined ? (
          props.data.map((item, idx) => {
            return <CardHolder key={idx + item.name} item={item} idx={idx} />;
          })
        ) : (
          <div>
            <h1>Loading butterfly collection</h1>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(DisplayCard);
